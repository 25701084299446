import { QueryClient, QueryClientProvider } from 'react-query';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React from 'react';
import { FuegoProvider } from '@nandorojo/swr-firestore';
import { AudioPlayerProvider } from '~/contexts/AudioPlayer';
import GlobalStyles from '../styles/Globals';
import { AppThemeProvider } from '~/contexts/Theme';
import { I18nProvider } from '~/contexts/Intl';
import Head from '~/components/Head';
import AudioPlayerControls from '~/components/AudioPlayerControls';
import { DataProvider } from '~/contexts/Data';
import useAnalytics from '~/hooks/useAnalytics';
import NprogressStyle from '~/styles/Nprogress';
import useProgressRouterWatcher from '~/hooks/useProgressRouterWatcher';
import { isBrowser } from '~/utils';
import { fuego } from '~/lib/fuego';
import 'react-lazy-load-image-component/src/effects/opacity.css';

if (!isBrowser) {
  Object.defineProperty(global, 'localStorage', {
    value: global.localStorage,
    configurable: true,
    enumerable: true,
    writable: true,
  });
}

const HicetnuncContextProvider = dynamic(() => import('~/contexts/Hicetnunc/__Provider'), {
  ssr: false,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
    },
  },
});

const usePrefetchUsuaulPages = () => {
  const router = useRouter();
  React.useEffect(() => {
    router.prefetch('/o/1');
    router.prefetch('/gwendall');
  }, [router]);
};

const App = ({ Component, pageProps }) => {
  useAnalytics();
  useProgressRouterWatcher();
  usePrefetchUsuaulPages();
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <Head { ...(pageProps.metaTags || {}) } />
      <HicetnuncContextProvider>
        <I18nProvider>
          <AppThemeProvider>
            <GlobalStyles />
            <NprogressStyle />
            <FuegoProvider fuego={ fuego }>
              <QueryClientProvider client={ queryClient }>
                <DataProvider>
                  <AudioPlayerProvider>
                    <Component { ...pageProps } />
                    <AudioPlayerControls />
                  </AudioPlayerProvider>
                </DataProvider>
              </QueryClientProvider>
            </FuegoProvider>
          </AppThemeProvider>
        </I18nProvider>
      </HicetnuncContextProvider>
    </>
  );
};

export default App;
