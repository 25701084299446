import React from 'react';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';

let currentId: string | null = null;
let progressBarTimeout = null;

const startProgress = () => {
  const id = Router.router.query.p as string;
  if (!id && !currentId) {
    clearTimeout(progressBarTimeout);
    progressBarTimeout = setTimeout(() => {
      NProgress.set(0.4);
      NProgress.start();
    }, 600);
  }
  currentId = id;
};
const stopProgress = () => {
  clearTimeout(progressBarTimeout);
  NProgress.done();
};

const useProgressRouterWatcher = () => {
  const router = useRouter();
  React.useEffect(() => {
    router.events.on('routeChangeStart', startProgress);
    router.events.on('routeChangeError', stopProgress);
    router.events.on('routeChangeComplete', stopProgress);
    return () => {
      router.events.off('routeChangeComplete', stopProgress);
      router.events.off('routeChangeStart', startProgress);
      router.events.off('routeChangeError', stopProgress);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useProgressRouterWatcher;
