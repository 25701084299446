import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import Link from 'next/link';
import { addHours, getMinutes, getHours, getSeconds } from 'date-fns';
import { BsChevronCompactDown, BsPause, BsPlay } from 'react-icons/bs';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { Portal } from '~/components/Modal';
import { useAudioPlayer } from '~/contexts/AudioPlayer';
import TextLight from './TextLight';
import LoadIcon from './LoadIcon';
import { getUserLink } from '~/graphql';
import { shortenAddress } from '~/utils';
import { User } from '~/types';
import TextTruncated from './TextTruncated';
import NftImage from './TokenAsset/types/Image';
import { PageColumn } from './Layout';
import { media } from '~/styles/media';
import { audioPlayerHeight } from '~/utils/const';
import FlexRow_ from './FlexRow';

const AudioPlayerControlsContainer = styled(motion.div)`
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.background};
  border-top: ${({ theme }) => theme.colors.borderXXLight} solid thin;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${audioPlayerHeight}px;
`;

const AudioPlayerControlsInner = styled(PageColumn)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FlexRow = styled(FlexRow_)`
  height: 100%;
`;

const TrackInfo = styled(FlexRow)`
  flex-shrink: 1;
  flex-grow: 0;
  flex: 1;
  ${media.mobile`
    flex: 1;
  `}
`;

const Controls = styled(FlexRow)`
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  height: max-content;
`;

const ControlButton = styled.button.attrs({
  type: 'button',
})<{
  $transparent?: boolean;
  $inverse?: boolean;
}>`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.foreground};
  color: ${({ theme }) => theme.colors.textLight};
  transition: color 100ms ease, transform 100ms ease;
  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.text};
    }
  }
  &:active {
    transform: scale(0.9);
  }
  ${({ $transparent }) => $transparent && `
    background-color: transparent;
  `}
  ${({ $inverse, theme }) => $inverse && `
    background-color: ${theme.colors.inverse};
    color: ${theme.colors.foreground};
    margin: 0 15px;
    @media (hover: hover) {
      &:hover {
        color: ${theme.colors.background};
      }
    }
  `}
  ${media.mobile`
    width: 44px;
    height: 44px;
  `}
`;

const controlsVariant = {
  initial: { bottom: -100 },
  isOpen: { bottom: 0 },
  exit: { bottom: -100 },
};

const getDuration = (secondsAmount: number = 0) => {
  if (!Number.isFinite(secondsAmount)) return '--:--';
  const normalizeTime = (time: string): string => (time.length === 1 ? `0${time}` : time);

  const SECONDS_TO_MILLISECONDS_COEFF = 1000;
  const MINUTES_IN_HOUR = 60;

  const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

  const date = new Date(milliseconds);
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

  const hoursOutput = hours !== '00' ? `${hours}:` : '';

  return `${hoursOutput}${minutes}:${seconds}`;
};

const Time = styled.div`
  padding: 0 10px;
`;

const ObjktImageContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.colors.foreground};
`;

const TrackControl = styled(FlexRow)`
  margin-top: 5px;
  ${media.mobile`
    display: none;
  `}
`;

const RightControls = styled.div`
  flex: 1;
  ${media.mobile`
    flex: 0;
  `}
`;

const AudioPlayerControls = () => {
  const {
    objkt,
    play,
    pause,
    close,
    isLoading,
    isPlaying,
    isPaused,
    trackProgress,
    duration,
    onScrub,
    onScrubEnd,
    goToPreviousTrack,
    goToNextTrack,
  } = useAudioPlayer();
  const handleClickPlay = () => play(objkt);
  const handleClickPause = () => pause();
  const handleClickClose = () => close();
  return (
    <Portal>
      <AnimatePresence>
        {
          objkt ? (
            <AudioPlayerControlsContainer
              key="backdrop"
              initial="initial"
              animate="isOpen"
              exit="exit"
              variants={ controlsVariant }
            >
              <AudioPlayerControlsInner>
                <TrackInfo>
                  <Link href={ `/o/${objkt.id}` } passHref>
                    <ObjktImageContainer as="a">
                      <NftImage src={ objkt.imageUrl } />
                    </ObjktImageContainer>
                  </Link>
                  <div>
                    <TextTruncated>
                      <Link href={ `/o/${objkt.id}` } passHref>
                        <a>
                          { objkt.title }
                        </a>
                      </Link>
                    </TextTruncated>
                    <div>
                      <Link href={ objkt.creator && getUserLink(objkt.creator || ({} as User)) } passHref>
                        <TextLight as="a">
                          { objkt.creator?.name || shortenAddress(objkt.creator?.address) }
                        </TextLight>
                      </Link>
                    </div>
                  </div>
                </TrackInfo>
                <Controls>
                  <FlexRow>
                    <ControlButton onClick={ goToPreviousTrack }>
                      <MdNavigateBefore size={ 20 } />
                    </ControlButton>
                    {
                      isLoading ? (
                        <ControlButton $inverse>
                          <LoadIcon $animating />
                        </ControlButton>
                      ) : isPaused || !isPlaying ? (
                        <ControlButton $inverse onClick={ handleClickPlay }>
                          <BsPlay size={ 20 } />
                        </ControlButton>
                      ) : (
                        <ControlButton $inverse onClick={ handleClickPause }>
                          <BsPause size={ 20 } />
                        </ControlButton>
                      )
                    }
                    <ControlButton onClick={ goToNextTrack }>
                      <MdNavigateNext size={ 20 } />
                    </ControlButton>
                  </FlexRow>
                  <TrackControl>
                    <Time>{ getDuration(trackProgress) }</Time>
                    <input
                      type="range"
                      value={ trackProgress }
                      step="1"
                      min="0"
                      max={ duration || `${duration}` }
                      onChange={ (e) => onScrub(+e.target.value) }
                      onMouseUp={ onScrubEnd }
                      onKeyUp={ onScrubEnd }
                      style={ { width: 200 } }
                    />
                    <Time>{ getDuration(duration) }</Time>
                  </TrackControl>
                </Controls>
                <RightControls>
                  <ControlButton
                    onClick={ handleClickClose }
                    $transparent
                    style={
                      {
                        marginLeft: 'auto',
                        position: 'relative',
                        top: -2,
                      }
                    }
                  >
                    <BsChevronCompactDown size={ 20 } />
                  </ControlButton>
                </RightControls>
              </AudioPlayerControlsInner>
            </AudioPlayerControlsContainer>
          ) : null
        }
      </AnimatePresence>
    </Portal>
  );
};

export default AudioPlayerControls;
