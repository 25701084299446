import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const NftImage = styled(LazyLoadImage).attrs({
  effect: 'opacity',
})`
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: middle;
  display: inline;
`;

export default NftImage;
