import React from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import fr from '~/locales/fr.json';
import en from '~/locales/en-US.json';

const locales = {
  fr,
  'en-US': en,
};

type I18nProviderProps = {
  children: React.ReactNode;
};

export const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => {
  const { locale, defaultLocale } = useRouter();
  const messages = React.useMemo(() => ({
    ...locales[defaultLocale],
    ...locales[locale],
  }), [locale, defaultLocale]);
  return (
    <IntlProvider locale={ locale } messages={ messages } onError={ () => null }>
      { children }
    </IntlProvider>
  );
};

const fallbackLocale = 'fr';
const baseTranslations = locales[fallbackLocale];
export type TranslationsType = keyof typeof baseTranslations;
type TranslationFn = (id: TranslationsType, data?: { [x: string]: string; }) => string;

export const useTranslation = () => {
  const intl = useIntl();
  const t: TranslationFn = (id, data) => intl.formatMessage({ id }, data);
  return { t };
};
