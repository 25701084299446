// @ts-nocheck
import { createGlobalStyle } from 'styled-components';
import { media } from './media';

const Global = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style-type: none;
    }
    html,
    body {
        width: 100%;
        height: 100%;
    }
    html {
        -webkit-text-size-adjust: 100%;
    }
    body {
        transition: background 300ms ease, color 300ms ease;
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.text};
        font-family: 'basier_circle_monoregular', 'Courier New', 'Courier', monospace;
        font-weight: 300;
        /*
        font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
        */
        line-height: 1.3rem;
        overflow-x: hidden;
        overflow-y: scroll;
        width: 100%;
        max-width: 100%;
        text-size-adjust: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: ${({ theme }) => theme.fontSize.regular};
        ${media.mobile`
            font-size: ${({ theme }) => theme.fontSize.regularMobile};
        `}
    }
    a {
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
            }
        }
    }
    button {
        cursor: pointer;
    }
    img {
        vertical-align: middle;
    }
    input,
    textarea {
        margin-bottom: 0;
        display: block;
        font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
            Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    }
    select,
    input[type="checkbox"] {
        cursor: pointer;
    }
    #WEB3_CONNECT_MODAL_ID > div {
        z-index: 9999;
    }
    .lazy-load-image-background.opacity.lazy-load-image-loaded {
        display: inline !important;
    }
`;

export default Global;
