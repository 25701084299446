import { createGlobalStyle } from 'styled-components';

const NprogressStyle = createGlobalStyle`
    #nprogress {
        pointer-events: none;
    }
    #nprogress .bar {
        background: #bc13fe;
        z-index: 1031;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }
    #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px #bc13fe, 0 0 5px #bc13fe;
        opacity: 1;
        -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
        transform: rotate(3deg) translate(0px, -4px);
    }
    #nprogress .spinner {
        display: "block";
        position: fixed;
        z-index: 1031;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
    .nprogress-custom-parent {
        overflow: hidden;
        position: relative;
    }
    .nprogress-custom-parent #nprogress .spinner,
    .nprogress-custom-parent #nprogress .bar {
        position: absolute;
    }
`;

export default NprogressStyle;
